"use client";
import React, { useEffect, useRef } from "react";
import { ThemeProvider } from "@bpipartners/webui";
import { getTheme } from "@guberno/utils/theme";
import { LicenseInfo, CssBaseline } from "@bpipartners/webui";
import { SnackbarProvider } from "notistack";
import { FaroWeb, Faro } from "./Faro";
import { FaroErrorBoundary } from "@grafana/faro-react";
import { FallbackErrorComponent } from "@guberno/ui";
import { SmallCheckMark } from "./SmallCheckmark";
import { ErrorBoundary } from "react-error-boundary";

LicenseInfo.setLicenseKey(
  "39b20c1effd4c09bb8a75446913ddebaTz04MDMzOSxFPTE3MzM4NDI0OTEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export const ClientProviders = ({
  children,
  isAnalticsEnabled,
}: {
  children?: React.ReactNode;
  isAnalticsEnabled?: string;
}) => {
  const faroRef = useRef<null | Faro>(null);

  useEffect(() => {
    if (
      !faroRef.current &&
      typeof window !== "undefined" &&
      isAnalticsEnabled === "true"
    ) {
      //@ts-ignore
      faroRef.current = FaroWeb();
    }
  }, [isAnalticsEnabled]);

  const shouldRenderErroBoundry = () => {
    if (isAnalticsEnabled === "false") {
      return (
        <ErrorBoundary
          FallbackComponent={FallbackErrorComponent}
        ></ErrorBoundary>
      );
    } else {
      return (
        <FaroErrorBoundary
          fallback={(error, reset) =>
            FallbackErrorComponent({ error, resetErrorBoundary: reset })
          }
        ></FaroErrorBoundary>
      );
    }
  };

  return (
    <ThemeProvider theme={getTheme()}>
      <SnackbarProvider
        autoHideDuration={3000}
        maxSnack={3}
        Components={{
          smallCheckMark: SmallCheckMark,
        }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {shouldRenderErroBoundry()}
        <CssBaseline />
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  );
};
